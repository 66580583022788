import React from 'react';

import { ReactComponent as TypeBug } from './bug.svg';
import { ReactComponent as TypeDark } from './dark.svg';
import { ReactComponent as TypeDragon } from './dragon.svg';
import { ReactComponent as TypeElectric } from './electric.svg';
import { ReactComponent as TypeFairy } from './fairy.svg';
import { ReactComponent as TypeFighting } from './fighting.svg';
import { ReactComponent as TypeFire } from './fire.svg';
import { ReactComponent as TypeFlying } from './flying.svg';
import { ReactComponent as TypeGhost } from './ghost.svg';
import { ReactComponent as TypeGrass } from './grass.svg';
import { ReactComponent as TypeGround } from './ground.svg';
import { ReactComponent as TypeIce } from './ice.svg';
import { ReactComponent as TypeNormal } from './normal.svg';
import { ReactComponent as TypePoison } from './poison.svg';
import { ReactComponent as TypePsychic } from './psychic.svg';
import { ReactComponent as TypeRock } from './rock.svg';
import { ReactComponent as TypeSteel } from './steel.svg';
import { ReactComponent as TypeWater } from './water.svg';

export default {
  bug: <TypeBug />,
  dark: <TypeDark />,
  dragon: <TypeDragon />,
  electric: <TypeElectric />,
  fairy: <TypeFairy />,
  fighting: <TypeFighting />,
  fire: <TypeFire />,
  flying: <TypeFlying />,
  ghost: <TypeGhost />,
  grass: <TypeGrass />,
  ground: <TypeGround />,
  ice: <TypeIce />,
  normal: <TypeNormal />,
  poison: <TypePoison />,
  psychic: <TypePsychic />,
  rock: <TypeRock />,
  steel: <TypeSteel />,
  water: <TypeWater />,
};
